<template>
  <div>
    <v-row
      :class="{
        'ml-12 pl-10': $vuetify.breakpoint.smAndUp,
        'mx-12': !$vuetify.breakpoint.smAndUp,
      }"
    >
      <v-col lg="7" md="7" class="pt-0 pb-0">
        <router-link to="/">
          <img
            :src="require('../assets/main_logo.png')"
            contain
            class="logo-img mt-5"
            height="100px"
          />
        </router-link>

        <v-form v-model="valid" ref="form" @submit.prevent="LoginUser()">
          <v-container class="login_container_css">
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '9' : '12'">
                <v-alert
                  close-icon="text-left"
                  v-if="error.non_field_errors"
                  color="red"
                  outlined
                  type="error"
                  >{{ error.non_field_errors }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :cols="$vuetify.breakpoint.smAndUp ? '9' : '12'"
                class="text-center"
              >
                <p class="login_text_css text-left">Log In</p>
                <v-text-field
                  class=""
                  :error-messages="error.username"
                  v-model="user.username"
                  type="text"
                  placeholder="Username"
                  :rules="[(v) => !!v || 'This field is required']"
                  autocomplete="off"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="text-center">
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '9' : '12'">
                <v-text-field
                  class=""
                  :error-messages="error.password"
                  v-model="user.password"
                  type="password"
                  placeholder="Password"
                  autocomplete="off"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '9' : '12'">
                <v-btn
                  x-large
                  type="submit"
                  rounded
                  class="login-btn header-add-login"
                  style=""
                  >Log In
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-10">
              <v-col :cols="$vuetify.breakpoint.smAndUp ? '9' : '12'">
                <v-row no-gutters>
                  <v-col align-self="center" class="mr-2"
                    >Don't have an account?</v-col
                  >
                  <v-col>
                    <v-btn
                      large
                      @click="createAccount"
                      rounded
                      color="primary"
                      class="header-add-login"
                      style=""
                      >Create Account
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <template> </template>
      </v-col>
      <v-col lg="5" md="5" class="pt-0 pb-0 login-image">
        <img src="../assets/corporate-buildings-m.png" />
      </v-col>
    </v-row>
    <v-footer>
      <v-col class="text-center footer-custome-css" cols="12">
        Copyright. @ 2022 All Right Reserved | Peterson Acquisitions..
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import { EventBus } from "../main";
import HeaderToggler from "../components/mixins/HeaderToggler";
import AuthServices from "../service/AuthServices";
import store from "../Store/index";
import CommonServices from "../service/CommonServices";
import { mapActions } from "vuex";

export default {
  name: "Login",
  mixins: [HeaderToggler],
  data() {
    return {
      loginURL: "api-token-auth",

      canpost: localStorage.getItem("canpost"),
      canput: localStorage.getItem("canput"),
      candelete: localStorage.getItem("candelete"),
      canviewreports: localStorage.getItem("canviewreports"),
      isAdmin: localStorage.getItem("Admin"),
      isReporter: localStorage.getItem("Reporter"),

      valid: false,
      store: store,
      error: {
        username: "",
        password: "",
        non_field_errors: "",
      },

      user_detail: {
        id: "",
        uid: "",
        employee_id: "",
        first_name: "",
        last_name: "",
        employee: "",
        user_type_id: "",
        user_type: "",
        active: "",
      },

      user: {
        username: "",
        password: "",
        role: 4, //default the user to a 'Reader' user role
        role_desc: "Reader",
        userid: "",
        employeeid: "",
        fullname: "",
        active: true,
        canpost: false,
        canput: false,
        candelete: false,
        canviewreports: false,
        teamAccess: false,
        fieldAccess: false,
      },
    };
  },

  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },

  methods: {
    ...mapActions(["updateUser"]),
    LoginUser() {
      if (this.$refs.form.validate()) {
        AuthServices.UserLogin(this.loginURL, this.user)
          .then((res) => {
            localStorage.setItem("token", res.token);
            localStorage.setItem("welcomeBack", 1); //flag to allow DIYWelcome page to navigate to the Welcome Back Page
            this.getUserData();
          })
          .catch((err) => {
            try {
              Object.keys(err.data).forEach((error) => {
                this.error[error] = err.data[error][0];
              });
            } catch (e) {
              this.error["non_field_errors"] = "Something Went Wrong";
            }
          });
      }
    },

    createAccount() {
      this.$router.push("/CreateAccount");
    },

    getUserData() {
      let userURL = `Users/?uid=${this.user.username}`;
      console.log("Got to getUserData");
      CommonServices.getList(userURL)
        .then((res) => {
          console.log("User Data: ", res);
          this.user_detail = res.results;
          console.log("user_detail: ", this.user_detail);
          this.user.username = this.user_detail[0].uid;
          localStorage.setItem("uid", this.user_detail[0].uid);

          this.user.first_name = this.user_detail[0].first_name;
          localStorage.setItem("first_name", this.user_detail[0].first_name);

          this.user.last_name = this.user_detail[0].last_name;
          localStorage.setItem("last_name", this.user_detail[0].last_name);

          if (this.user_detail[0].user_photo_file !== undefined)
            localStorage.setItem(
              "user_photo_file",
              this.user_detail[0].user_photo_file
            );
          else localStorage.removeItem("user_photo_file");

          this.user.role = this.user_detail[0].user_type_id;
          localStorage.setItem("role", this.user_detail[0].user_type_id);

          this.user.role_desc = this.user_detail[0].user_type;
          localStorage.setItem("role_desc", this.user_detail[0].user_type);

          this.user.userid = this.user_detail[0].id;
          localStorage.setItem("userid", this.user_detail[0].id);
          localStorage.setItem("email", this.user_detail[0].email);

          this.user.employeeid = this.user_detail[0].employee_id;
          localStorage.setItem("employeeid", this.user_detail[0].employee_id);

          this.user.fullname = this.user_detail[0].employee;
          localStorage.setItem("fullname", this.user_detail[0].employee);

          this.user.active = this.user_detail[0].active;
          localStorage.setItem("active", this.user_detail[0].active);

          this.$store.commit("user", this.user);
          console.log("Store State Role: ", store.state.role);

          //Reset all permissions before assigning them
          localStorage.setItem("canput", false);
          localStorage.setItem("canpost", false);
          localStorage.setItem("candelete", false);
          localStorage.setItem("canviewreports", false);
          localStorage.setItem("Admin", false);
          localStorage.setItem("Reporter", false);
          localStorage.setItem("teamAccess", false);
          localStorage.setItem("fieldAccess", false);
          localStorage.setItem("DIY", false);
          console.log(
            "Status after refresh: ",
            this.canput,
            this.canpost,
            this.candelete,
            this.canviewreports,
            this.isAdmin,
            this.isReporter
          );

          if (store.state.role === 1) {
            localStorage.setItem("Admin", true);

            this.user.canpost = true;
            localStorage.setItem("canpost", true);

            this.user.canput = true;
            localStorage.setItem("canput", true);

            this.user.candelete = true;
            localStorage.setItem("candelete", true);

            this.user.canviewreports = true;
            localStorage.setItem("canviewreports", true);

            this.user.teamAccess = true;
            localStorage.setItem("teamAccess", true);

            this.user.fieldAccess = true;
            localStorage.setItem("fieldAccess", true);
          }

          if (store.state.role == 2) {
            this.user.canput = true;
            localStorage.setItem("canput", true);
            localStorage.setItem("canpost", false);
            localStorage.setItem("candelete", false);
            localStorage.setItem("canviewreports", false);
          }

          if (store.state.role == 3) {
            this.user.canpost = true;
            localStorage.setItem("canpost", true);
            this.user.canput = true;
            localStorage.setItem("canput", true);
          }

          if (store.state.role == 4) {
            this.user.canviewreports = true;
            localStorage.setItem("canviewreports", true);
          }

          if (store.state.role == 5) {
            localStorage.setItem("Reporter", true);
            this.user.canviewreports = true;
            localStorage.setItem("canviewreports", true);
          }

          if (store.state.role_desc == "HR") {
            this.user.canviewreports = true;
            localStorage.setItem("canviewreports", true);

            this.user.canpost = true;
            localStorage.setItem("canpost", true);

            this.user.canput = true;
            localStorage.setItem("canput", true);

            this.user.candelete = true;
            localStorage.setItem("candelete", true);

            this.user.teamAccess = true;
            localStorage.setItem("teamAccess", true);
          }

          if (store.state.role_desc == "Office") {
            this.user.canviewreports = true;
            localStorage.setItem("canviewreports", true);

            this.user.canpost = true;
            localStorage.setItem("canpost", true);

            this.user.canput = true;
            localStorage.setItem("canput", true);

            this.user.candelete = true;
            localStorage.setItem("candelete", true);

            this.user.fieldAccess = true;
            localStorage.setItem("fieldAccess", true);
          }

          if (store.state.role_desc == "DIY") {
            this.user.DIY = true;
            localStorage.setItem("DIY", true);
          }

          this.$store.commit("user", this.user);
          console.log(
            "Store State: ",
            store.state.username,
            store.state.role,
            store.state.canpost,
            store.state.candelete,
            store.state.canviewreports,
            store.state.role_desc,
            store.state.userid,
            store.state.role,
            store.state.employeeid,
            store.state.fullname
          );

          console.log(
            "Local Storage: ",
            localStorage.getItem("role_desc"),
            localStorage.getItem("canpost"),
            localStorage.getItem("canput"),
            localStorage.getItem("candelete"),
            localStorage.getItem("canviewreports")
          );
          //Add user data to store for use in other parts of application
          EventBus.$emit("toggle-nav", localStorage.getItem("token") !== null);
          if (this.mobileDevice()) {
            this.$router.push("/Evaluation");
          } else if (store.state.role_desc == "DIY") {
            this.$router.push(`/DIYWelcome/${store.state.userid}`);
          } else {
            this.$router.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped type="text/css">
.login-btn {
  background-color: #0157a0 !important;
  color: #fff !important;
}

.login-image {
  height: 100vh;
  padding-right: 0;
}

.login-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

footer {
  position: absolute !important;
  bottom: 0;
  left: 0;
  max-width: 59%;
  right: 0 !important;
  background-color: transparent !important;
}

.forgot_password_css {
  text-align: end;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  color: #0157a0;
}

.login_text_css {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold !important;
  font-size: 25px;
}

.login_container_css {
  margin-top: 50px;
}

.header-add-login {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}
.v-image__image .v-image__image--contain {
  background-position: left !important;
}

@media (max-width: 600px) {
  .login-image {
    display: none;
  }

  .login_container_css {
    margin-top: 15px;
  }

  footer {
    max-width: 100%;
  }
}
</style>
